<template>
  <div class="programmeDetails">
    <div class="userItem">
      <div class="user-info flex-between">
        <div class="info-item-box">
          <div class="info-item flex-between">
            <div class="lable">透析方式：</div>
            <div class="cont" v-if="ruleForm.dict_dialysis_type">
              {{ gitDictVal(dictList[100000000], ruleForm.dict_dialysis_type) }}
            </div>
            <div class="cont" v-else>--</div>
          </div>
          <div class="info-item flex-between">
            <div class="lable">附加体重：</div>
            <div
              class="cont"
              v-if="ruleForm.add_weight || ruleForm.add_weight === 0"
            >
              {{ ruleForm.add_weight }}kg
            </div>
            <div class="cont" v-else>--</div>
          </div>
          <div class="info-item flex-between">
            <div class="lable">回血量：</div>
            <div
              class="cont"
              v-if="ruleForm.huixue_num || ruleForm.huixue_num === 0"
            >
              {{ ruleForm.huixue_num }}ml
            </div>
            <div class="cont" v-else>--</div>
          </div>
          <div class="info-item flex-between">
            <div class="lable">冲水方式：</div>
            <div class="cont" v-if="ruleForm.dict_flush_mode">
              {{ gitDictVal(dictList[339500000], ruleForm.dict_flush_mode) }}
            </div>
            <div class="cont" v-else>--</div>
          </div>
          <div class="info-item flex-between">
            <div class="lable">指定时间：</div>
            <div class="cont" v-if="ruleForm.make_time">
              {{ ruleForm.make_time }}
            </div>
            <div class="cont" v-else>--</div>
          </div>
        </div>
        <div class="info-item-box">
          <div class="info-item flex-between">
            <div class="lable">透析时常：</div>
            <div class="cont" v-if="ruleForm.dialysis_hour">
              {{ ruleForm.dialysis_hour }}时{{ ruleForm.dialysis_min }}分
            </div>
            <div class="cont" v-else>--</div>
          </div>
          <div class="info-item flex-between">
            <div class="lable">最大脱水：</div>
            <div
              class="cont"
              v-if="ruleForm.max_tuoshui_num || ruleForm.max_tuoshui_num === 0"
            >
              {{ ruleForm.max_tuoshui_num }}kg
            </div>
            <div class="cont" v-else>--</div>
          </div>
          <div class="info-item flex-between">
            <div class="lable">回血泵速：</div>
            <div
              class="cont"
              v-if="
                ruleForm.huixue_beng_speed || ruleForm.huixue_beng_speed === 0
              "
            >
              {{ ruleForm.huixue_beng_speed }}mL/min
            </div>
            <div class="cont" v-else>--</div>
          </div>
          <div class="info-item flex-between">
            <div class="lable">护理等级：</div>
            <div class="cont" v-if="ruleForm.dict_huli_level">
              {{ gitDictVal(dictList[119000000], ruleForm.dict_huli_level) }}
            </div>
            <div class="cont" v-else>--</div>
          </div>
          <div class="info-item flex-between">
            <div class="lable">制定人：</div>
            <div class="cont" v-if="ruleForm.assign_user_id">
              {{ gitDictVal(userNameList, ruleForm.assign_user_id) }}
            </div>
            <div class="cont" v-else>--</div>
          </div>
        </div>
        <div class="info-item-box norig">
          <div class="info-item flex-between">
            <div class="lable">干体重：</div>
            <div
              class="cont"
              v-if="ruleForm.dry_weight || ruleForm.dry_weight === 0"
            >
              {{ ruleForm.dry_weight }}kg
            </div>
            <div class="cont" v-else>--</div>
          </div>
          <div class="info-item flex-between">
            <div class="lable">血流速：</div>
            <div
              class="cont"
              v-if="
                ruleForm.xueliu_speed_min || ruleForm.xueliu_speed_min === 0
              "
            >
              {{ ruleForm.xueliu_speed_min }}mL/min
            </div>
            <div class="cont" v-else>--</div>
          </div>
          <div class="info-item flex-between">
            <div class="lable">超滤方式：</div>
            <div class="cont" v-if="ruleForm.dict_chaolv_mode">
              {{ gitDictVal(dictList[339400000], ruleForm.dict_chaolv_mode) }}
            </div>
            <div class="cont" v-else>--</div>
          </div>
          <div class="info-item flex-between">
            <div class="lable">透析频次：</div>
            <div class="cont" v-if="ruleForm.dict_dialysis_cycle">
              {{
                gitDictVal(dictList[117000000], ruleForm.dict_dialysis_cycle)
              }}
            </div>
            <div class="cont" v-else>--</div>
          </div>
        </div>
      </div>
    </div>
    <div class="userItem">
      <div class="user-info flex-between">
        <div class="info-item-box">
          <div class="info-item flex-between">
            <div class="lable">透析液温度：</div>
            <div
              class="cont"
              v-if="ruleForm.touxiye_temp || ruleForm.touxiye_temp === 0"
            >
              {{ ruleForm.touxiye_temp }}℃
            </div>
            <div class="cont" v-else>--</div>
          </div>
          <div class="info-item flex-between">
            <div class="lable">Ca：</div>
            <div class="cont" v-if="ruleForm.ca || ruleForm.ca === 0">
              {{ ruleForm.ca }}mmol/L
            </div>
            <div class="cont" v-else>--</div>
          </div>
        </div>
        <div class="info-item-box">
          <div class="info-item flex-between">
            <div class="lable">透析液流速：</div>
            <div
              class="cont"
              v-if="ruleForm.touxiye_speed || ruleForm.touxiye_speed === 0"
            >
              {{ ruleForm.touxiye_speed }}ml/min
            </div>
            <div class="cont" v-else>--</div>
          </div>
          <div class="info-item flex-between">
            <div class="lable">NA：</div>
            <div class="cont" v-if="ruleForm.na || ruleForm.na === 0">
              {{ ruleForm.na }}ml/min
            </div>
            <div class="cont" v-else>--</div>
          </div>
        </div>
        <div class="info-item-box norig">
          <div class="info-item flex-between">
            <div class="lable">K：</div>
            <div class="cont" v-if="ruleForm.k || ruleForm.k === 0">
              {{ ruleForm.k }}ml/min
            </div>
            <div class="cont" v-else>--</div>
          </div>
          <div class="info-item flex-between">
            <div class="lable">HCO3-：</div>
            <div class="cont" v-if="ruleForm.hco3 || ruleForm.hco3 === 0">
              {{ ruleForm.hco3 }}ml/min
            </div>
            <div class="cont" v-else>--</div>
          </div>
        </div>
      </div>
    </div>
    <div class="userItem">
      <div class="user-info flex-between">
        <div class="info-item-box">
          <div class="info-item flex-between">
            <div class="lable">抗凝剂：</div>
            <div class="cont" v-if="ruleForm.dict_kangning">
              {{ gitDictVal(dictList[116000000], ruleForm.dict_kangning) }}
            </div>
            <div class="cont" v-else>--</div>
          </div>
          <div class="info-item flex-between">
            <div class="lable">追加/维持：</div>
            <div class="cont" v-if="ruleForm.dict_chaolv_mode">
              {{ gitDictVal(dictList[339400000], ruleForm.dict_chaolv_mode) }}
            </div>
            <div class="cont" v-else>--</div>
          </div>
        </div>
        <div class="info-item-box">
          <div class="info-item flex-between">
            <div class="lable">首剂：</div>
            <div class="cont" v-if="ruleForm.kangning_shouji_num">
              {{ ruleForm.kangning_shouji_num }}
              {{
                gitDictVal(
                  dictList[157000000],
                  ruleForm.dict_kangning_shouji_unit
                )
              }}
            </div>
            <div class="cont" v-else>--</div>
          </div>
          <div class="info-item flex-between">
            <div class="lable">维持时间：</div>
            <div class="cont" v-if="ruleForm.kangning_add_time_hour">
              {{ ruleForm.kangning_add_time_hour }}
            </div>
            <div class="cont" v-else>--</div>
          </div>
        </div>
      </div>
    </div>
    <div class="userItem">
      <div class="user-info flex-between">
        <div class="info-item-box">
          <div class="info-item flex-between">
            <div class="lable">血透器：</div>
            <div class="cont" v-if="ruleForm.dict_touxiqi">
              {{ gitDictVal(dictList[113000000], ruleForm.dict_touxiqi) }}
            </div>
            <div class="cont" v-else>--</div>
          </div>
          <div class="info-item flex-between">
            <div class="lable">置换方式：</div>
            <div class="cont" v-if="ruleForm.dict_zhihuan_type">
              {{ gitDictVal(dictList[118000000], ruleForm.dict_zhihuan_type) }}
            </div>
            <div class="cont" v-else>--</div>
          </div>
        </div>
        <div class="info-item-box">
          <div class="info-item flex-between">
            <div class="lable">血滤器：</div>
            <div class="cont" v-if="ruleForm.dict_xuelvqi">
              {{ gitDictVal(dictList[114000000], ruleForm.dict_xuelvqi) }}
            </div>
            <div class="cont" v-else>--</div>
          </div>
          <div class="info-item flex-between">
            <div class="lable">置换总量：</div>
            <div
              class="cont"
              v-if="ruleForm.zhihuan_num || ruleForm.zhihuan_num === 0"
            >
              {{ ruleForm.zhihuan_num }}L
            </div>
            <div class="cont" v-else>--</div>
          </div>
        </div>
        <div class="info-item-box norig">
          <div class="info-item flex-between">
            <div class="lable">灌流器：</div>
            <div class="cont" v-if="ruleForm.dict_guanliuqi">
              {{ gitDictVal(dictList[115000000], ruleForm.dict_guanliuqi) }}
            </div>
            <div class="cont" v-else>--</div>
          </div>
          <div class="info-item flex-between">
            <div class="lable">置换流速：</div>
            <div
              class="cont"
              v-if="
                ruleForm.zhihuan_speed_min || ruleForm.zhihuan_speed_min === 0
              "
            >
              {{ ruleForm.zhihuan_speed_min }}ml/min
            </div>
            <div class="cont" v-else>--</div>
          </div>
        </div>
      </div>
    </div>
    <div class="userItem">
      <div class="user-info flex-between">
        <div class="info-item-box">
          <div class="info-item flex-between">
            <div class="lable">备注：</div>
            <div class="cont">{{ ruleForm.remark }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { gitDictVal } from '@/utils/tool.js'
export default {
  name: 'programmeDetailsDio',
  setup() {
    const userInfo = JSON.parse(localStorage.getItem('ms_userData'))
    const state = reactive({
      ruleForm: {},
      infoHei: '',
      dictList: {},
      userNameList: [
        {
          name: localStorage.getItem('ms_username'),
          code: userInfo.id,
          id: userInfo.id,
        },
      ],
    })

    const initData = (row, item) => {
      state.ruleForm = row
      if (!state.dictList[100000000]) {
        state.dictList = item
      }
    }

    return {
      ...toRefs(state),
      gitDictVal,
      initData,
    }
  },
}
</script>

<style scoped lang="scss">
.userItem {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 30px;
}
.user-info {
  flex-wrap: wrap;
}
.info-item-box {
  width: 320px;
  // margin-right: 5%;
  // padding: 0 20px;
  padding-left: 20px;
  font-family: 'Source Han Sans CN-Regular';
  &.norig {
    margin-right: 0;
  }
  .info-item {
    font-size: 14px;
    color: #444444;
    margin-bottom: 24px;
  }
}
</style>

<template>
  <div class="scheme">
    <BaseDialog
      title="调取模板"
      :isshow="visible"
      @handleShow="handleShow"
      width="79%"
      height="auto"
    >
      <div class="schemeBox">
        <div class="templateTable">
          <el-table
            ref="singleTableRef"
            highlight-current-row
            :data="tableData"
            @current-change="handleCurrentChange"
            border
            size="small"
            style="width: 100%"
            max-height="164"
          >
            >
            <el-table-column prop="dialysis_type" label="透析方式">
            </el-table-column>
            <el-table-column
              prop="assign_user"
              label="制定人"
            ></el-table-column>
            <el-table-column prop="updated_time" label="制定时间" />
            <el-table-column prop="remark" label="备注" />
            <el-table-column fixed="right" label="操作" width="100">
              <template #default="scope">
                <el-button
                  type="text"
                  size="small"
                  @click.prevent.stop="setDialysis(scope.row)"
                >
                  <i class="txIons use"></i>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="contents">
          <el-scrollbar>
            <div class="padRight-20">
              <programmeDetails
                v-if="isProgrammeDetails"
                ref="programmeDetailsRef"
              ></programmeDetails>
            </div>
          </el-scrollbar>
        </div>
      </div>
    </BaseDialog>
  </div>
</template>
<script>
import { reactive, toRefs, nextTick } from 'vue'
import service from '@/utils/request'
import { ElMessageBox } from 'element-plus'
import BaseDialog from '@/components/Dialog/index.vue'
import programmeDetails from '@/components/patient/programmeDetails.vue'
export default {
  name: 'dialysisScheme',
  components: {
    BaseDialog,
    programmeDetails,
  },
  emits: ['tempData'],
  setup(props, ctx) {
    const state = reactive({
      dataForm: {},
      ruleForm: {},
      visible: false,
      tableData: [],
      dictList: {},
      isProgrammeDetails: false,
      programmeDetailsRef: null,
    })

    const initData = (row) => {
      state.visible = true
      state.dictList = row
      getList()
    }

    const getList = async () => {
      let res = await service.post(
        '/api/patient_dialysis/get_patient_dialysis_tpl_list'
      )
      if (res.code === 0) {
        state.tableData = res.data.patient_dialysis_tpl_list
      }
    }

    const handleCurrentChange = (row) => {
      if (!row) return
      state.isProgrammeDetails = true
      nextTick(() => {
        state.programmeDetailsRef.initData(row, state.dictList)
      })
    }

    const setDialysis = (row) => {
      ElMessageBox.confirm(
        '使用模板数据会重置当前表单，确定要使用吗?',
        '操作提示',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          state.visible = false
          ctx.emit('tempData', row)
        })
        .catch(() => {})
    }

    const handleShow = (val) => {
      state.visible = val
    }

    return {
      ...toRefs(state),
      initData,
      handleShow,
      handleCurrentChange,
      setDialysis,
    }
  },
}
</script>

<style scoped lang="scss">
.templateTable {
  margin-top: 30px;
  margin-bottom: 30px;
}
.contents {
  height: 500px;
  overflow: hidden;
  .userItem {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 30px;
  }
  .demo-ruleForms .el-form-item {
    width: 100%;
    margin-right: 0;
  }
  .el-form-item {
    width: 30%;
    margin-right: 5%;
    &.addr {
      width: 65%;
      margin-right: 0;
    }
    &:deep(.el-form-item__content) {
      // width: calc(100% - 104px);
      min-width: 150px;
    }
  }
  .el-form-item:nth-child(3n + 3) {
    margin-right: 0;
  }
}

.dialog-footer {
  text-align: right;
  margin-top: 20px;
  .color-cancel {
    background: #ffffff;
    color: #3166ae;
    margin-right: 10px;
  }
}
</style>
